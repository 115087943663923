import request from "@/utils/request";

export function login(data) {
  return request({
    // url: '/vue-element-admin/user/login',
    url: "/auth/login",
    method: "post",
    data,
  });
}

export function getInfo(token) {
  return request({
    url: "/auth/info",
    // url: '/vue-element-admin/user/info',
    method: "get",
    params: { token },
  });
}

export function logout(token) {
  return request({
    url: "/auth/logout",
    method: "get",
    params: { token },
  });
}
