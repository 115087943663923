<template>
  <div class="about padding-top screen padding-lr-sm">
    <!-- <van-cell class="padding-lr-0" title="我的团队" value="内容" /> -->
    <div class="flex align-center padding-tb">
      <div class="title text-lg text-bold">2024年-年中考核设置</div>
    </div>
    <div class="top padding-tb">
      <div class="text-left text-df">请对团队成员没人设置至少5个互评人</div>
    </div>
    <div class="flex align-center padding-top">
      <div class="point bg-blue margin-right-xs"></div>
      <div class="title text-df">我的团队</div>
    </div>

    <div class="padding-sm flex align-center justify-between margin-bottom-xs">
      <div class="flex justify-start flex-direction">
        <div class="title text-df">张三</div>
      </div>
      <van-button round type="info" size="small">添加互评人</van-button>
    </div>
    <div class="response margin-bottom flex justify-start">
      <van-tag
        v-if="show"
        class="margin-right-sm padding-sm"
        closeable
        plain
        size="medium"
        type="primary"
        @close="close"
      >
        张三
      </van-tag>
      <van-tag
        v-if="show"
        class="margin-right-sm padding-sm"
        closeable
        plain
        size="medium"
        type="primary"
        @close="close"
      >
        王五
      </van-tag>
    </div>
    <div class="padding-sm flex align-center justify-between margin-bottom-xs">
      <div class="flex justify-start flex-direction">
        <div class="title text-df">张三</div>
      </div>
      <van-button round type="info" size="small">添加互评人</van-button>
    </div>
    <div class="padding-sm flex align-center justify-between margin-bottom-xs">
      <div class="flex justify-start flex-direction">
        <div class="title text-df">张三</div>
      </div>
      <van-button round type="info" size="small">添加互评人</van-button>
    </div>
    <div class="padding-sm flex align-center justify-between margin-bottom-xs">
      <div class="flex justify-start flex-direction">
        <div class="title text-df">张三</div>
      </div>
      <van-button round type="info" size="small">添加互评人</van-button>
    </div>
    <div class="padding-sm flex align-center justify-between margin-bottom-xs">
      <div class="flex justify-start flex-direction">
        <div class="title text-df">张三</div>
      </div>
      <van-button round type="info" size="small">添加互评人</van-button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Tab,
  Tabs,
  Grid,
  GridItem,
  Image,
  ActionSheet,
  Divider,
  Cell,
  List,
  CellGroup,
  Rate,
  Field,
  Button,
  Tag,
} from "vant";

Vue.use(CellGroup);
Vue.use(List);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(ActionSheet);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Image);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Rate);
Vue.use(Field);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Divider);

export default {
  name: "Settings",
  components: {
    // Button,
    // DropdownMenu,
    // Popover,
    // BizCalendarChooseDateTime,
    // BizContactComplexPicker,
  },
  methods: {
    close() {
      this.show = false;
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style scoped lang="scss">
.screen {
  max-width: 600px;
  margin: auto;
}
::v-deep .van-cell__title {
  display: flex;
  justify-content: flex-start;
}
</style>