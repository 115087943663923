var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about padding-top screen padding-lr-sm"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"padding-sm flex align-center justify-between margin-bottom-xs"},[_vm._m(3),_c('van-button',{attrs:{"round":"","type":"info","size":"small"}},[_vm._v("添加互评人")])],1),_c('div',{staticClass:"response margin-bottom flex justify-start"},[(_vm.show)?_c('van-tag',{staticClass:"margin-right-sm padding-sm",attrs:{"closeable":"","plain":"","size":"medium","type":"primary"},on:{"close":_vm.close}},[_vm._v(" 张三 ")]):_vm._e(),(_vm.show)?_c('van-tag',{staticClass:"margin-right-sm padding-sm",attrs:{"closeable":"","plain":"","size":"medium","type":"primary"},on:{"close":_vm.close}},[_vm._v(" 王五 ")]):_vm._e()],1),_c('div',{staticClass:"padding-sm flex align-center justify-between margin-bottom-xs"},[_vm._m(4),_c('van-button',{attrs:{"round":"","type":"info","size":"small"}},[_vm._v("添加互评人")])],1),_c('div',{staticClass:"padding-sm flex align-center justify-between margin-bottom-xs"},[_vm._m(5),_c('van-button',{attrs:{"round":"","type":"info","size":"small"}},[_vm._v("添加互评人")])],1),_c('div',{staticClass:"padding-sm flex align-center justify-between margin-bottom-xs"},[_vm._m(6),_c('van-button',{attrs:{"round":"","type":"info","size":"small"}},[_vm._v("添加互评人")])],1),_c('div',{staticClass:"padding-sm flex align-center justify-between margin-bottom-xs"},[_vm._m(7),_c('van-button',{attrs:{"round":"","type":"info","size":"small"}},[_vm._v("添加互评人")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex align-center padding-tb"},[_c('div',{staticClass:"title text-lg text-bold"},[_vm._v("2024年-年中考核设置")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top padding-tb"},[_c('div',{staticClass:"text-left text-df"},[_vm._v("请对团队成员没人设置至少5个互评人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex align-center padding-top"},[_c('div',{staticClass:"point bg-blue margin-right-xs"}),_c('div',{staticClass:"title text-df"},[_vm._v("我的团队")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-start flex-direction"},[_c('div',{staticClass:"title text-df"},[_vm._v("张三")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-start flex-direction"},[_c('div',{staticClass:"title text-df"},[_vm._v("张三")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-start flex-direction"},[_c('div',{staticClass:"title text-df"},[_vm._v("张三")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-start flex-direction"},[_c('div',{staticClass:"title text-df"},[_vm._v("张三")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-start flex-direction"},[_c('div',{staticClass:"title text-df"},[_vm._v("张三")])])
}]

export { render, staticRenderFns }